import axios from 'axios';

const settings = {
  namespaced: true,
  state: {
    timeValues: false,
    delayValue: false,
  },
  mutations: {
    SET_TIME_VALUES(state, data) {
      state.timeValues = data;
    },
    SET_DELAY_VALUE(state, data) {
      state.delayValue = data;
    },
  },
  actions: {
    getSettings({ rootState, commit }) {
      axios.get(`${rootState.apiURL}/getSettings`).then((res) => {
        const settings = res.data[0];
        if (settings) {
          commit('SET_TIME_VALUES', settings.timeValues);
          commit('SET_DELAY_VALUE', settings.delayValue);
        }
      });
    },
    updateSettings({ rootState, commit }, payload) {
      axios.post(`${rootState.apiURL}/updateSettings`, payload).then((res) => {
        const data = res.data;
        if (data) {
          commit('SET_DELAY_VALUE', data.delayValue);
        }
      });
    },
  },
  getters: {
    getTimeValues(state) {
      return state.timeValues;
    },
    getDelayValue(state) {
      return state.delayValue;
    },
  },
};
export default settings;
